import "./bootstrap";
import "../css/app.css";

import {createApp, h} from "vue";
import {createInertiaApp} from "@inertiajs/vue3";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {ZiggyVue} from "../../vendor/tightenco/ziggy/dist/vue.m";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || "DocsGuide";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),
    setup({el, App, props, plugin}) {
        const vm = createApp({render: () => h(App, props)});

        Sentry.init({
            trackComponents: true,
            environment: import.meta.env.VITE_APP_ENV,
            app: vm,
            dsn: import.meta.env.VITE_APP_ENV === "production" ? "https://2ca2b817e272cbbd315b9533dba89725@o4507095381770240.ingest.de.sentry.io/4507095517757520" : null,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                Sentry.browserTracingIntegration(),
                new Sentry.BrowserProfilingIntegration(),
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                /^https:\/\/docsguide\.test/,
                /^https:\/\/docsguide\.de/,
                /^https:\/\/docsgui\.de/,
            ],

            // Set profilesSampleRate to 1.0 to profile every transaction.
            // Since profilesSampleRate is relative to tracesSampleRate,
            // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
            // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
            // results in 25% of transactions being profiled (0.5*0.5=0.25)
            profilesSampleRate: 0.25,
        });

        return vm.use(plugin).use(ZiggyVue).mount(el);
    },
    progress: {
        color: "#4e8999",
    },
});
